export default Object.freeze({
	DRAFT: 'Draft',
	QUOTED: 'Quoted',
	BOUND: 'Bound',
	DECLINED: 'Declined',
	NOT_TAKEN: 'NotTaken',
	CLOSED: 'Closed',
	QUOTING: 'Quoting',
	BINDING: 'Binding',
	RENEWING: 'Renewing',
	NON_RENEWING: 'NonRenewing',
	NOT_TAKING: 'NotTaking',
	CANCELING: 'Canceling',
	RESCINDING: 'Rescinding',
	RESCINDED: 'Rescinded',
	REINSTATING: 'Reinstating',
	AUDIT_COMPLETE: 'AuditComplete',
	WAIVED: 'Waived',
	IN_FORCE: 'InForce_icare',
	EXPIRED: 'Expired',
	WITHDRAWN: "Withdrawn",
});
