import request from 'superagent';
import uuidv4 from 'uuid/v4';
import { BASE_API_URL } from '~/config/index';

/**
 * Deep merge two JSONs
 */

const merge = (target, source) => {
	// Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
	for (const key of Object.keys(source)) {
		if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
	}

	// Join `target` and modified `source`
	Object.assign(target || {}, source)
	return target
}

/**
 * Manage Your Policy - Change CSP Submit Service
 */

export default class CancelPolicyServices {


	static async submitCancelPolicyRequest(policyModel, cancelPolicyModel, clientId, clientSecret, token) {

		const policyData = policyModel.toServiceLayerCancelPolicy;
		const cancelPolicyData = cancelPolicyModel.toServiceLayerCancelPolicy;
		const policyId = policyModel.policyNumber;
		const payload = merge(policyData, cancelPolicyData);
		
		const url = `${BASE_API_URL}/v2/portal/workersInsurance/tokenAuth/policies/${policyId}`;
		return request
			.post(`${url}`)
			.type('form')
			.set({
				Accept: '*/*',
				'Cache-Control': 'no-cache',
				'Content-Type': 'application/vnd.api+json',
				'X-TrackingID': `portalRequest-${uuidv4()}`,
				'X-InitialSystem': 'WIPolicyPortal',
				'X-Client-ID': `${clientId}`,
				'X-Client-Secret': `${clientSecret}`,
			})
			.set({'X-Token': token ? token : 'XXX'})
			.send(payload);
	}
}