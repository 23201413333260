import { observable, computed, action } from 'mobx';
import uuidv4 from 'uuid/v4';
import policyTransactionType from "../constants/policyTransactionType";

export default class PolicyAuth {

	@observable id = uuidv4();

	@observable policyTransactionType = null;

	@observable policyNumber = null;

	@observable policyExpiryDate = null;

	@observable policyEstimatedWageEndDate = null;

	@observable policyCancellationEndDate = null;

	@observable policyUniqueCode = null;

	@observable policyPostCode = null;

	@observable cocWhoIsRequesting = null;
	@observable cocFirstName = null;
	@observable cocLastName = null;
	@observable cocCompanyName = null;
	@observable cocContactNumber = null;
	@observable cocEmailAddress = null;
	@observable cocPosition = null;
	/**
	 * Set the wage type
	 * @param {string} value - wage type
	 */
	@action setPolicyTransactionType(value) {
		this.policyTransactionType = value || null;
	}

	/**
	 * Set the policy number
	 * @param {string} value - The policy number
	 */
	@action setPolicyNumber(value) {
		this.policyNumber = value || null;
	}

	/**
	 * Set the policy expiry date
	 * @param {?string} value - The policy expiry date
	 */
	@action setPolicyExpiryDate(value) {
		this.policyExpiryDate = value || null;
	}

	/**
	 * Set the estimated wages policy end date
	 * @param {?string} value - The policy end date
	 */
	@action setPolicyEstimatedWageEndDate(value) {
		this.policyEstimatedWageEndDate = value || null;
	}

	/**
	 * Set the ecancellation policy end date
	 * @param {?string} value - The policy end date
	 */
	@action setPolicyCancellationEndDate(value) {
		this.policyCancellationEndDate = value || null;
	}
	

	/**
	 * Set the policy unique code
	 * @param {?string} value - The policy unique code
	 */
	@action setPolicyUniqueCode(value) {
		this.policyUniqueCode = value || null;
	}

	/**
	 * Set the policy post code
	 * @param {?string} value - The policy post code
	 */
	@action setPolicyPostCode(value) {
		this.policyPostCode = value || null;
	}

	/**
	 * Set the COC request by
	 * @param {?string} value w
	 */
	@action setFormCOCWhoIsRequesting(value) {
		this.cocWhoIsRequesting = value || null;
	}

	/**
	 * Set the COC FirstName
	 * @param {?string} value w
	 */
	@action setFormCOCFirstName(value) {
		this.cocFirstName = value || null;
	}

	/**
	 * Set the COC LastName
	 * @param {?string} value w
	 */
	@action setFormCOCLastName(value) {
		this.cocLastName = value || null;
	}

	/**
	 * Set the COC CompanyName
	 * @param {?string} value w
	 */
	@action setFormCOCCompanyName(value) {
		this.cocCompanyName = value || null;
	}

	/**
	 * Set the COC ContactNumber
	 * @param {?string} value w
	 */
	@action setFormCOCContactNumber(value) {
		this.cocContactNumber = value || null;
	}

	/**
	 * Set the COC EmailAddress
	 * @param {?string} value w
	 */
	@action setFormCOCEmailAddress(value) {
		this.cocEmailAddress = value || null;
	}

	/**
	 * Set the COC Position
	 * @param {?string} value w
	 */
	@action setFormCOCPosition(value) {
		this.cocPosition = value || null;
	}


	@computed
	get toServiceLayerPrelimInfo() {
		let expDate = null;
		if (this.policyTransactionType === policyTransactionType.ACTUAL_WAGES) {
			expDate = this.policyExpiryDate;
		} else if (this.policyTransactionType === policyTransactionType.ESTIMATED_WAGES) {
			expDate = this.policyEstimatedWageEndDate;
		} else if (this.policyTransactionType === policyTransactionType.CUSTOMER_SERVICE_PROVIDER) {
			//expDate = moment(new Date()).format('MM/DD/YYYY');
			expDate = null;
		} else if (this.policyTransactionType === policyTransactionType.CANCEL_POLICY) {
			expDate = this.policyCancellationEndDate;
		} else if (this.policyTransactionType === policyTransactionType.CERTIFICATE_OF_CURRENCY) {
			return {
				type: 'PolicyDocumentSearch',
				id: this.id,
				attributes: {
					criteria: {
							policyNumber: this.policyNumber || null,
							policyPostcode: this.policyPostCode || null,
							documentType: "NI_WI_CERT_CURRENCY",
						},
					params:{
						auditInfo: {
							requestedBy: this.cocWhoIsRequesting,
							firstName: this.cocFirstName,
							lastName: this.cocLastName,
							position: this.cocPosition,
							companyName: this.cocCompanyName,
							emailAddress: this.cocEmailAddress,
							contactNumber: this.cocContactNumber,
						}
					},
				},
			};
		}
		return {
			type: 'PolicySearch',
			id: this.id,
			attributes: {
				criteria: {
					policyNumber: this.policyNumber || null,
					policyExpiryDate: expDate,
					policyPostcode: this.policyPostCode || null,
					transactionType: this.policyTransactionType,
				},
			},
		};
	}

	@computed
	get toServiceLayerPrelimInitialInfo() {
		return {
			type: 'policyValidation',
			id: this.id,
			attributes: {
				criteria: {
					policyNumber: this.policyNumber || null,
					policyPostcode: this.policyPostCode || null,
				},
			},
		};
	}
}

export class PrelimInfoFactory {
	/**
	 * Create an Wage from a plain old object
	 * @param prelimInfo
	 * @returns {?Wage}
	 */
	static createFromFormObject(prelimInfo) {
		if (!prelimInfo) {
			return null;
		}
		const policyAuth = new PolicyAuth();

		policyAuth.setPolicyNumber(prelimInfo.policyNumber);
		policyAuth.setPolicyExpiryDate(prelimInfo.policyExpiryDate);
		policyAuth.setPolicyUniqueCode(prelimInfo.policyUniqueCode);
		policyAuth.setPolicyPostCode(prelimInfo.policyPostCode);
		policyAuth.setPolicyTransactionType(prelimInfo.policyTransactionType);
		policyAuth.setPolicyEstimatedWageEndDate(prelimInfo.policyEstimatedWageEndDate);
		policyAuth.setPolicyCancellationEndDate(prelimInfo.policyCancellationEndDate);

		policyAuth.setFormCOCWhoIsRequesting(prelimInfo.cocWhoIsRequesting);
		policyAuth.setFormCOCFirstName(prelimInfo.cocFirstName);
		policyAuth.setFormCOCLastName(prelimInfo.cocLastName);
		policyAuth.setFormCOCCompanyName(prelimInfo.cocCompanyName);
		policyAuth.setFormCOCContactNumber(prelimInfo.cocContactNumber);
		policyAuth.setFormCOCEmailAddress(prelimInfo.cocEmailAddress);
		policyAuth.setFormCOCPosition(prelimInfo.cocPosition);
		return policyAuth;
	}
}
