import { observable, action, computed } from 'mobx';
import uuidv4 from 'uuid/v4';
import { WageFactory } from './WageModel';
import { PolicyLocationFactory } from './PolicyLocation';
import policyTransactionType from '../constants/policyTransactionType';

export default class PolicyModel {
	@observable id = uuidv4();

	@observable policyNumber = null;

	@observable groupNumber = null;

	@observable employerName = null;

	@observable policyStartDate = null;

	@observable policyPeriodId = null;

	@observable policyEndDate = null;

	@observable termNumber = null;

	@observable policyLocationPublicID = null;

	@observable policyLocation = [];

	@observable wics = [];

	@observable submitWics = [];

	@observable SubmitterModel = null;

	@observable transactionType = null;

	@observable currentGeneralistCSP = null;

	@observable currentSpecialistCSP = null;

	@observable isCSPEditable = false;

	@observable generalistCSPs = [];

	@observable specialistCSPs = [];

	@observable accountId = null;

	/**
	 * Set Account id
	 * @param value
	 */
	@action setAccountId(value) {
		this.accountId = value || null;
	}

	/**
	 * Set the trading name
	 * @param {string} -- Current generalist CSP
	 */
	@action setCurrentGeneralistCSP(value) {
		this.currentGeneralistCSP = value || null;
	}

	/**
	 * Set the trading name
	 * @param {string} -- Current specialist CSP
	 */
	@action setCurrentSpecialistCSP(value) {
		this.currentSpecialistCSP = value || null;
	}

	/**
	 * Set the trading name
	 * @param {Boolean} -- CSP Editale
	 */
	@action setIsCSPEditable(value) {
		this.isCSPEditable = value || false;
	}

	/**
	 * Set the trading name
	 * @param {Array[string]} -- Generalist CSP List
	 */
	@action setGeneralistCSPs(value) {
		this.generalistCSPs = value || [];
	}

	/**
	 * Set the trading name
	 * @param {Array[string]} -- Specialist CSP List
	 */
	@action setSpecialistCSPs(value) {
		this.specialistCSPs = value || [];
	}

	/**
	 * Set the Policy Number
	 * @param {string} value - The Policy Number
	 */
	@action setPolicyNumber(value) {
		this.policyNumber = value || null;
	}

	/**
	 * Set the Group Number
	 * @param {?string} value - The Group Number
	 */
	@action setGroupNumber(value) {
		this.groupNumber = value || null;
	}

	/**
	 * Set the Employer Name
	 * @param {?string} value - The Employer Name
	 */
	@action setEmployerName(value) {
		this.employerName = value || null;
	}

	/**
	 * Set the Policy StartDate
	 * @param {?string} value - The Policy StartDate
	 */
	@action setPolicyStartDate(value) {
		this.policyStartDate = value || null;
	}

	/**
	 * Set the Policy EndDate
	 * @param {?string} value - The Policy EndDate
	 */
	@action setPolicyEndDate(value) {
		this.policyEndDate = value || null;
	}

	/**
	 * Set the Policy cancellationDate
	 * @param {?string} value - The Policy cancellationDate
	 */
	@action setCancellationDate(value) {
		this.cancellationDate = value || null;
	}

	/**
	 * Set the policyPeriodId
	 * @param {?string} value - The policyPeriodId
	 */
	@action setPolicyPeriod(value) {
		this.policyPeriodId = value || null;
	}

	/**
	 * Set the Term Number
	 * @param {?string} value - The Term Number
	 */
	@action setTermNumber(value) {
		this.termNumber = value || null;
	}

	/**
	 * Set the Policy Location
	 * @param {?import('./PolicyLocation').default} value
	 */
	@action setPolicyLocation(value) {
		this.policyLocation = value || null;
	}

	/**
	 * Set the trading name
	 * @param {?import('./SubmitterModel').default} value
	 */
	@action setSubmitterModel(value) {
		this.SubmitterModel = value || null;
	}

	/**
	 * Set the transactionType
	 * @param {?istring} value - The Type of transaction - Estimated wages or Actual wages
	 */
	@action setTransactionType(value) {
		this.transactionType = value || null;
	}

	/**
	 * Set the trading name
	 * @param {?import('./Wage').default} value
	 */
	@action setWics(value) {
		this.wics = value || null;
	}

	/**
	 * Set the isRenewalPolicy
	 * @param {?boolean} value - The Renewal policy flag - Estimated wages. For Actual wages it will be NULL
	 */
	@action setIsRenewalPolicy(value) {
		this.isRenewalPolicy = !!value;
	}

	/**
	 * Set the trading name
	 * @param {Array[string]} -- Certificate of currency link
	 */
	@action setCOCLink(value) {
		this.link = value || [];
	}
	// endregion

	@computed
	get totalWages() {
		let totalWages = 0;
		if (this.wics && this.wics.length) {
			this.wics.forEach(wagesItem => {
				totalWages += wagesItem.totalWages;
			});
		}
		return totalWages;
	}

	@computed
	get totalEmployees() {
		let totalEmployees = 0;
		if (this.wics && this.wics.length) {
			this.wics.forEach(wagesItem => {
				totalEmployees += wagesItem.totalEmployees;
			});
		}
		return totalEmployees;
	}

	@computed
	get toServiceLayerWageDeclaration() {
		const payload = {
			type: 'PolicyPeriodWage',
			attributes: {
				submitter: {
					isEmployee: this.SubmitterModel.isEmployee,
					firstName: this.SubmitterModel.firstName,
					lastName: this.SubmitterModel.lastName,
					position: this.SubmitterModel.position,
					company: this.SubmitterModel.company,
					email: this.SubmitterModel.email,
					contact: {
						phone: [
							{
								contactNumber: this.SubmitterModel.contactNumber,
							},
						],
					},
				},
				transactionType: this.SubmitterModel.transactionType,
				wages: this.buildWages(),
				policyStartDate: this.SubmitterModel.policyStartDate,
				policyEndDate: this.SubmitterModel.policyEndDate,
				totalNoOfEmployees: this.SubmitterModel.totalNoOfEmployees,
				totalWages: this.SubmitterModel.totalWages,
				totalNoOfUnits: this.SubmitterModel.totalNoOfUnits,
				employerName: this.SubmitterModel.employerName,
			},
		};
		if (this.SubmitterModel.transactionType === policyTransactionType.ESTIMATED_WAGES) {
			return {
				...payload,
				attributes: {
					...payload.attributes,
					reasonForChange: this.SubmitterModel.reasonForChange,
					isRenewalPolicy: this.SubmitterModel.isRenewalPolicy,
				},
			};
		} else {
			return payload;
		}
	}

	@action buildTaxi(wagesItem) {
		return wagesItem && wagesItem.TaxiObject
			? wagesItem.TaxiObject.map(taxiObj => ({
				id: taxiObj.taxiPlateFixedID ? taxiObj.taxiPlateFixedID : null,
				taxiPlate: taxiObj.taxiPlate,
				startDate: taxiObj.taxiPlateStartDate,
				endDate: taxiObj.taxiPlateEndDate,
			}))
			: [];
	}

	@action buildContractors(wagesItem) {
		let ContractorWagesObject = null;
		if (wagesItem && wagesItem.ContractorWages && wagesItem.ContractorWages.length) {
			const contractorWages = wagesItem.ContractorWages[0];
			const contractors = [];
			const contract = {
				id: null,
				type: null,
				noOfWorkers: null,
				totalContractValue: null,
				labourCost: null,
				description: null,
				perCent: null,
			};
			contract.id = contractorWages.contractorWageFixedID ? contractorWages.contractorWageFixedID : null;
			contract.type = contractorWages.contractType;
			contract.noOfWorkers = contractorWages.noOfContractWorkers;
			const totalContractValues = contractorWages.totalContractValue
				? Number(contractorWages.totalContractValue.toString().replace(/[^0-9\.-]+/g, ''))
				: 0;
			contract.totalContractValue = totalContractValues;
			const contractorWage = contractorWages.labourCost
				? Number(contractorWages.labourCost.toString().replace(/[^0-9\.-]+/g, ''))
				: 0;
			contract.labourCost = contractorWage;
			contract.description = contractorWages.contractorWageDescription;
			contract.perCent = contractorWages.contractorWagePercentage;
			contractors.push(contract);
			ContractorWagesObject = contractors;
		}
		return ContractorWagesObject;
	}

	@action buildAsbestoWorker(wagesItem) {
		let asbestosObject = null;
		if (wagesItem && wagesItem.asbestosObject && wagesItem.asbestosObject.length) {
			asbestosObject = { workDescription: null, noOfWorkers: null, wages: null };
			asbestosObject.workDescription = wagesItem.asbestosObject[0].asbestosWorkDescription;
			asbestosObject.noOfWorkers = wagesItem.asbestosObject[0].noOfExposedWorkers;
			const asbestosWage = wagesItem.asbestosObject[0].asbestosGrossWages
				? Number(wagesItem.asbestosObject[0].asbestosGrossWages.toString().replace(/[^0-9\.-]+/g, ''))
				: 0;
			asbestosObject.wages = asbestosWage;
		}
		return asbestosObject;
	}

	@action buildWages() {
		const wages = [];
		const wagelist = this.submitWics.length === 0 ? this.wics : this.wics.concat(this.submitWics);
		if (wagelist && wagelist.length) {
			wagelist.forEach(wagesItem => {
				if (wagesItem) {
					const wage = {};
					if (!wagesItem.isRemoved) {
						wage.id = wagesItem.fixedID;
						wage.location = wagesItem.publicLocationID ? { id: wagesItem.publicLocationID } : { id: null };
						wage.costCenter = { id: null };
						wage.wics = {
							code: wagesItem.classificationCode,
							pacCode: wagesItem.pac,
							businessDescription: wagesItem.businessDescription,
						};

						const apprenticeWage = wagesItem.apprenticeWages
							? Number(wagesItem.apprenticeWages.toString().replace(/[^0-9\.-]+/g, ''))
							: 0;
						wage.noOfApprentices =
							wagesItem.apprenticeNumber && wagesItem.apprenticeNumber > 0 ? wagesItem.apprenticeNumber : 0;
						wage.apprenticeWages = apprenticeWage;
						wage.noOfEmployees = wagesItem.employees && wagesItem.employees > 0 ? wagesItem.employees : 0;
						wage.taxiPlates = wagesItem.isPercapita && wagesItem.isTaxi ? this.buildTaxi(wagesItem) : null;
						wage.noOfUnits = wagesItem.isPercapita && !wagesItem.isTaxi ? wagesItem.unitCount : 0;
						wage.asbestos =
							!wagesItem.isPercapita && !wagesItem.isTaxi && wagesItem.asbestosObject && wagesItem.asbestosObject.length
								? this.buildAsbestoWorker(wagesItem)
								: null;
						wage.contractors =
							!wagesItem.isPercapita &&
								!wagesItem.isTaxi &&
								wagesItem.ContractorWages &&
								wagesItem.ContractorWages.length
								? this.buildContractors(wagesItem)
								: null;
						wage.nationalQualificationCode = wagesItem.nationalQualificationCode
							? wagesItem.nationalQualificationCode
							: null;
						wage.totalWages = wagesItem.wages ? Number(wagesItem.wages.toString().replace(/[^0-9\.-]+/g, '')) : 0;
						wage.isPerCapita = wagesItem.isPercapita;
						wage.isTaxi = wagesItem.isTaxi;
						wages.push(wage);
					} else {
						if (!wagesItem.isNew) {
							wage.id = wagesItem.fixedID;
							wage.location = wagesItem.publicLocationID ? { id: wagesItem.publicLocationID } : { id: null };
							wage.costCenter = { id: null };
							wage.wics = {
								code: wagesItem.classificationCode,
								pacCode: wagesItem.pac,
								businessDescription: wagesItem.businessDescription,
							};
							wage.noOfApprentices = 0;
							wage.apprenticeWages = 0;
							wage.noOfEmployees = 0;
							wage.taxiPlates = null;
							wage.noOfUnits = 0;
							wage.asbestos = null;
							wage.contractors = null;
							wage.nationalQualificationCode = wagesItem.nationalQualificationCode
								? wagesItem.nationalQualificationCode
								: null;
							wage.totalWages = 0;
							wage.isPerCapita = wagesItem.isPercapita;
							wage.isTaxi = wagesItem.isTaxi;
							wages.push(wage);
						}
					}
				}
			});
		}
		return wages;
	}

	@computed
	get toServiceLayerCancelPolicy() {
		const payload = {
			data: {
				type: 'cancelPolicy',
				id: this.id,
				attributes: {
						policyPeriodID: this.policyPeriodId,
						transactionType: "CANCELPOLICY",
						policyStartDate: this.policyStartDate,
						policyEndDate: this.policyEndDate,
						employerName: this.employerName,
				},
			},
		};
		return payload;
	}

	@computed
	get toServiceLayerChangeCSP() {
		const payload = {
			data: {
				type: 'PolicyValidation',
				attributes: {
					criteria: {
						transactionType: this.transactionType,
						employerName: this.employerName,
						accountId: this.accountId,
						groupNumber: this.groupNumber,
						currentGeneralistCSP: this.currentGeneralistCSP,
						currentSpecialistCSP: this.currentSpecialistCSP,
					},
				},
			},
		};
		return payload;
	}
}

export class PolicyFactory {
	/**
	 * Create an Wage from a plain old object
	 * @param policy
	 * @returns {?PolicyModel}
	 */
	static createFromService(policy) {
		if (!policy) {
			return null;
		}
		const policyModel = new PolicyModel();
		policyModel.setPolicyNumber(policy.attributes?.policyNumber);
		policyModel.setGroupNumber(policy.attributes?.groupNumber);
		policyModel.setEmployerName(policy.attributes?.employerName);
		policyModel.setPolicyStartDate(policy.attributes?.policyStartDate);
		policyModel.setPolicyEndDate(policy.attributes?.policyEndDate);
		policyModel.setCancellationDate(policy.attributes?.cancellationDate);
		policyModel.setTermNumber(policy.attributes?.policyEndDate);
		policyModel.setPolicyPeriod(policy.attributes?.policyPeriodId);
		policyModel.setTransactionType(policy?.attributes?.transactionType);
		policyModel.setIsRenewalPolicy(policy?.attributes?.isRenewalPolicy);
		policyModel.setCurrentGeneralistCSP(policy?.attributes?.currentGeneralistCSP);
		policyModel.setCurrentSpecialistCSP(policy?.attributes?.currentSpecialistCSP);
		policyModel.setIsCSPEditable(policy?.attributes?.isCSPEditable);
		policyModel.setGeneralistCSPs(policy?.attributes?.generalistCSPs);
		policyModel.setSpecialistCSPs(policy?.attributes?.specialistCSPs);
		policyModel.setAccountId(policy?.attributes?.accountId);
		policyModel.setCOCLink(policy[0]?.attributes?.link);

		if (policy.attributes?.policyLocation && policy.attributes.policyLocation.length) {
			policyModel.setPolicyLocation(
				policy.attributes.policyLocation.map(PolicyLocationFactory.createFromServiceObject).filter(i => i),
			);
			policyModel.setWics(
				policy.attributes.policyLocation.flatMap(item =>
					item.costCenters
						.flatMap(costCenter => costCenter.wics.map(WageFactory.createFromServiceObject))
						.map(wic => Object.assign(wic, { publicLocationID: item.publicID })),
				),
			);
		} else {
			policyModel.setWics([]);
		}

		return policyModel;
	}
}
