export default Object.freeze({
	INDIVIDUAL_SOLE_TRADER: 'individualsoletrader',
	PARTNERSHIPS: 'partnership',
	TRUST: 'trustestate',
	COMPANY: 'company',
	STRATA_PLAN: 'strataplan',
	SUPER_FUND: 'superannuationfund',
	GOVERNMENT: 'government',
	OVERSEAS_COMPANY: 'overseasregcompany',
	JOINT_VENTURE: 'jointventure',
	CHARITY: 'registeredcharities',
	CHURCH_GROUP: 'churchgroups',
	SPORT: 'sportingorganisation',
	REGISTERED_CHARITIES: 'registeredcharities',
	OTHER_INCORPORATED_ENTITY: 'otherincorporatedentity_ext',
});
