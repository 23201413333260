import {observable, computed, action} from 'mobx';

import ContactDetails, {ContactDetailsFactory} from './ContactDetails';
import Address, {AddressFactory} from './Address';

export class Contact {
	/**
	 * @type {?string}
	 */
	@observable id = null;
	/**
	 * @type {?ContactDetails}
	 */
	@observable details = null;
	/**
	 * @type {?Address}
	 */
	@observable address = null;
	/**
	 * @type {?boolean}
	 */
	@observable isUserPrimaryContact = null;
	/**
	 * @type {?boolean}
	 */
	@observable contactUpdateFlag = false; //DEFAULT to false. Will be set to true once take out policy is successful and  data.attributes.draftData.accountContact[].publicId is not null
	/**
	 * @type {?boolean}
	 */
	@observable contactRetired = false; //DEFAULT to false. In case when users to additional contacts then go back and remove them, this field should become true for those removed secondary contacts
	/**
	 * @type {?string}
	 */
	@observable publicId = null;

	/**
	 * @type {?string}
	 */
	@observable publicIdForContactAddress = null;

	/**
	 * Set the Primary Contact Details
	 *
	 * @param {?ContactDetails}
	 */
	@action
	setDetails(details) {
		if (!details) {
			this.details = new ContactDetails();
		} else if (details instanceof ContactDetails) {
			this.details = details;
		} else {
			throw new TypeError('Invalid details', details);
		}
	}

	@action
	setAddress(value) {
		if (!(value instanceof Address) && value !== null) {
			throw new TypeError();
		}
		this.address = value;
	}

	@action
	setContactUpdateFlag(contactUpdateFlag) {
		this.contactUpdateFlag = contactUpdateFlag;
	}

	@action
	setContactRetired(contactRetired) {
		this.contactRetired = contactRetired;
	}

	@action
	setUserPrimaryContact(value) {
		this.isUserPrimaryContact = value;
	}

	@action
	setPublicId(value) {
		this.publicId = value;
	}

	@action
	setPublicIdForContactAddress(value) {
		this.publicIdForContactAddress = value;
	}

	@computed
	get toServiceLayer() {
		return {
			personContact: this.details ? this.details.toServiceLayer : null,
			contactAddress: this.address
				? [
						{
							...this.address.toServiceLayer,
							addressType: 'postal',
							publicId: this.publicIdForContactAddress,
						},
				  ]
				: null,
			contactRole: ['LocationContact_icare'], //DEFAULT
			contactUpdateFlag: this.contactUpdateFlag,
			contactRetired: this.contactRetired,
			publicId: this.publicId,
		};
	}

	@computed
	get toServiceLayerPolicyEdit() {
		return {
			personContact: this.details.toServiceLayer,
			contactAddress: [
				{
					...this.address.toServiceLayer,
					addressType: 'postal',
					publicId: this.publicIdForContactAddress,
				},
			],
			contactRole: ['LocationContact_icare'], //DEFAULT
			contactUpdateFlag: this.contactUpdateFlag,
			contactRetired: this.contactRetired,
			publicId: this.publicId,
		};
	}
}

export class ContactFactory {
	static createFromObject(o) {
		if (!o) {
			return null;
		}

		const c = new Contact();

		if (o.details || o.personContact) {
			c.setDetails(ContactDetailsFactory.createFromObject(o.details || o.personContact));
		}

		if (o.address || (o.contactAddress && o.contactAddress.length)) {
			c.setAddress(AddressFactory.createAddressFromObject(o.address || o.contactAddress[0]));
		}

		if (o.contactUpdateFlag) {
			c.setContactUpdateFlag(o.contactUpdateFlag);
		}

		if (o.contactRetired) {
			c.setContactRetired(o.contactRetired);
		}

		if ((o.details && o.details.isUserPrimaryContact) || o.isUserPrimaryContact) {
			c.setUserPrimaryContact((o.details && o.details.isUserPrimaryContact) || o.isUserPrimaryContact);
		}

		if (o.publicId) {
			c.setPublicId(o.publicId);
		}

		return c;
	}

	static createFromResponseObject(o) {
		if (!o) {
			return null;
		}

		const c = new Contact();

		if (o.personContact) {
			c.setDetails(ContactDetailsFactory.createFromObject(o.personContact));
		}

		if (o.contactAddress && o.contactAddress.length) {
			c.setAddress(AddressFactory.createAddressFromObject(o.contactAddress[0]));
		}

		return c;
	}
}

export default Contact;
